import cx from "classnames";
import React from "react";
import TextButton from "../../../../TextButton";
import * as styles from "./SelectorContent.module.scss";

class SelectorContent extends React.Component {
  render() {
    const arr = this.props.title.split(" ");
    const boldText = arr.slice(-1)[0];
    const text = arr.slice(0, -1).join(" ");
    const className = this.props.animationClassName
      ? cx(styles.selector_content, this.props.animationClassName)
      : styles.selector_content;
    return (
      <div className={cx(className, this.props.extraClassName)}>
        <div className={styles.title}>
          {`${text} `}
          <b>{boldText}</b>
        </div>
        <img
          className={styles.illustration}
          src={this?.props?.illustration?.file?.url}
          alt="content illustration"
        />
        <div className={styles.description}>
          {this.props.description.description}
        </div>
        <TextButton
          text={this.props.cta}
          href={null}
          className={styles.text_button}
        />
      </div>
    );
  }
}

export default SelectorContent;
