import cx from 'classnames'
import * as _ from 'lodash'
import React from 'react'
import FeatureCard from './FeatureCard'
import * as styles from './FeatureSection.module.scss'

class FeatureSection extends React.Component {
  render() {
    return (
      <section className={styles.feature_section}>
        <div className={styles.title}>{this?.props?.title}</div>
        <div className={cx(styles.feature_cards, 'container')}>
          {_.map(this.props.features, (feature, idx) => {
            return (
              <FeatureCard
                key={idx}
                title={feature?.title}
                description={feature?.description}
                illustration={feature?.illustration?.file?.url}
              />
            )
          })}
        </div>
      </section>
    )
  }
}

export default FeatureSection
