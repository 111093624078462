import { graphql } from "gatsby";
import React from "react";
import BrandBar from "../components/AmazonPage/BrandBar";
import FeatureSection from "../components/AmazonPage/FeatureSection";
import Hero from "../components/AmazonPage/Hero";
import MarketerSection from "../components/AmazonPage/MarketerSection";
import SupportedAdSection from "../components/AmazonPage/SupportedAdSection";
import BottomCTA from "../components/BottomCta";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { nav } from "../utils/navigation";
import { CTA } from "../constants/text";
import { URL } from "../constants/destinations";
import { useTranslation } from 'gatsby-plugin-react-i18next';

// TODO: (window.location.href = `https://app.perpetua.io/signup/`)
const AmazonAdvertisingPage: React.FC<{}> = props => {
  const { t } = useTranslation();
  const homepages = props?.data?.home?.edges;
  const currentHomePage = homepages && homepages[0].node;

  return (
    <Layout primaryButtonId="marketplace-amazon-nav-getstarted">
      <SEO
        title={currentHomePage.metadataTitle}
        description={currentHomePage.metadataDescription}
      />
      <NavigationBarSpacer />
      <Hero
        title={currentHomePage.heroTitle}
        description={currentHomePage.heroDescription}
        image={currentHomePage.heroImage.fluid}
      />
      <BrandBar customers={currentHomePage.featuredCustomers} />
      <SupportedAdSection
        title={currentHomePage.supportedAdvertisementSectionTitle}
        adTypes={currentHomePage.supportedAdvertisementTypes}
      />
      <FeatureSection
        title={currentHomePage.featuresSectionTitle}
        features={currentHomePage.features}
      />
      <MarketerSection
        title={currentHomePage.marketersSectionTitle}
        marketers={currentHomePage.marketers}
      />
      <BottomCTA
        primaryButtonId="marketplace-amazon-bottom-getstarted"
        secondaryButtonId="marketplace-amazon-bottom-demo"
        title={currentHomePage.bottomCtaTitle}
        subtitle={currentHomePage.bottomCtaSubtitle}
        primaryButtonText={t(CTA.GetStarted)}
        secondaryButtonText={t(CTA.Demo)}
        primaryButtonOnClick={() => {
          nav(URL.SignUp);
        }}
      />
    </Layout>
  );
};

export default AmazonAdvertisingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    home: allContentfulHomePage(filter: {node_locale: {eq: $language}}) {
      edges {
        node {
          metadataTitle
          metadataDescription
          heroTitle
          heroDescription
          heroImage {
            fluid(maxWidth: 1432) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          featuredCustomers {
            customerName
            logo {
              fluid(maxWidth: 120) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          supportedAdvertisementSectionTitle
          supportedAdvertisementTypes {
            title
            description {
              description
            }
            illustration {
              file {
                url
              }
            }
            newAdType
            managedService
          }
          featuresSectionTitle
          features {
            title
            description
            illustration {
              file {
                url
              }
            }
          }
          marketersSectionTitle
          marketers {
            title
            description {
              description
            }
            illustration {
              file {
                url
              }
            }
            cta
          }
          caseStudies {
            title
            featuredCustomer {
              logo {
                fluid(maxWidth: 200) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              largeLogo {
                fluid(maxWidth: 232) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            publication {
              bannerImage {
                fluid(maxWidth: 378) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              slug
              bodyPreview
            }
            embeddedContent {
              script {
                script
              }
            }
            isFeatured
          }
          bottomCtaTitle
          bottomCtaSubtitle
          createdAt
        }
      }
    }
  }
`;