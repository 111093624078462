import cx from "classnames";
import * as _ from "lodash";
import React from "react";
// import ArrowButton, { ButtonDirection } from '../../PerpetuaButtons/ArrowButton'
import * as styles from "./MarketerSection.module.scss";
import SelectorContent from "./views/SelectorContent";

class MarketerSection extends React.Component {
  state = {
    previousIndex: null,
    currentIndex: 0,
    inAnimationClassName: null,
    outAnimationClassName: null
  };

  handleOptionClick = (e, idx) => {
    // compute the index of the marketers that
    // will be shown next
    const n = this.props.marketers.length;
    const nextIndex = ((idx % n) + n) % n;
    if (nextIndex === this.state.currentIndex) {
      return;
    }

    // remove animation class from content
    // so the same one can be repeated if necessary
    let target = e && e.currentTarget;
    this.setState(
      { inAnimationClassName: null, outAnimationClassName: null },
      () => {
        // animate in the opposite direction of the slider
        if (nextIndex > this.state.currentIndex) {
          this.setState({
            inAnimationClassName: "fade_in_right",
            outAnimationClassName: "fade_out_left"
          });
        } else if (nextIndex < this.state.currentIndex) {
          this.setState({
            inAnimationClassName: "fade_in_left",
            outAnimationClassName: "fade_out_right"
          });
        } else {
          this.setState({ inAnimationClassName: null });
        }
        this.setState({
          previousIndex: this.state.currentIndex,
          currentIndex: nextIndex
        });

        if (this.slider && target) {
          this.slider.style.left = `${target.offsetLeft - 17}px`;
        }
      }
    );
  };

  render() {
    const currentContent = this.props.marketers[this.state.currentIndex];
    const previousContent = this.props.marketers[this.state.previousIndex || 0];
    return (
      <section className={cx(styles.marketer_section, "container")}>
        {/*<div className={styles.subtitle}>Marketers</div>*/}
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.selector}>
          <div className={styles.options_container}>
            {_.map(this.props.marketers, (marketers, idx) => {
              const arr = marketers.title.split(" ");
              const boldText = arr.slice(-1)[0];
              const text = arr.slice(0, -1).join(" ");
              const classname =
                idx === this.state.currentIndex
                  ? cx(styles.option, styles.active)
                  : styles.option;
              return (
                <div
                  key={idx}
                  className={classname}
                  onClick={e => this.handleOptionClick(e, idx)}
                >
                  <p className={styles.label}>
                    {`${text} `}
                    <b>{boldText}</b>
                  </p>
                </div>
              );
            })}
            <div className={styles.track}></div>
            <div
              className={styles.slider}
              ref={ref => (this.slider = ref)}
            ></div>
          </div>
        </div>
        {/* <ArrowButton
        className={styles.mobile_left_button}
        direction={ButtonDirection.LEFT}
        onClick={() => this.handleOptionClick(null, this.state.currentIndex - 1)} />
      <ArrowButton
        className={styles.mobile_right_button}
        direction={ButtonDirection.RIGHT}
        onClick={() => this.handleOptionClick(null, this.state.currentIndex + 1)} /> */}

        <div className={styles.animation_container}>
          {/* previous index this is only visible during animation */}
          {this.state.previousIndex !== null && (
            <SelectorContent
              ref={ref => (this.previousContent = ref)}
              title={previousContent.title}
              illustration={previousContent.illustration}
              cta={previousContent.cta}
              description={previousContent.description}
              extraClassName={"mobile_hidden"}
              animationClassName={this.state.outAnimationClassName}
            />
          )}

          {/* current index */}
          <SelectorContent
            ref={ref => (this.content = ref)}
            title={currentContent.title}
            illustration={currentContent.illustration}
            cta={currentContent.cta}
            description={currentContent.description}
            animationClassName={this.state.inAnimationClassName}
          />
        </div>
      </section>
    );
  }
}

export default MarketerSection;
