import cx from "classnames";
import Img from "gatsby-image";
import React from "react";
import { nav } from "../../../utils/navigation";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import * as styles from "./Hero.module.scss";
import { CTA } from "../../../constants/text";
import { URL, Intercom } from "../../../constants/destinations";
import { Type, Name, Location } from "../../../utils/analytics/constants"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Hero = (props) => {
  const { title, description, image } = props;

  const {t} = useTranslation();

    return (
      <section className={cx("container", styles.hero)}>
        <div className={styles.hero_text}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
          <div className={styles.buttons}>
            <Button
              id="marketplace-amazon-header-getstarted"
              text={t(CTA.GetStarted)}
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              onClick={() => {
                nav(URL.SignUp);
              }}
            />
            <Button
              id="marketplace-amazon-header-contactus"
              text={t(CTA.Contact)}
              variant={ButtonVariant.secondary}
              size={ButtonSize.large}
            />
          </div>
        </div>
        <Img
          fluid={image}
          alt={"browser preview"}
          className={styles.hero_image}
        />
      </section>
    );
}

export default Hero;
