import React from 'react'
import * as _ from 'lodash'
import cx from 'classnames'

import FeaturedSupportedAd from './FeaturedSupportedAd'

import * as styles from './SupportedAdSection.module.scss'

class SupportedAdSection extends React.Component {
  render() {
    return (
      <section className={cx(styles.supported_ad_section, 'container')}>
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.supported_ads}>
          {_.map(this.props.adTypes, (at, idx) => {
            return (
              <FeaturedSupportedAd
                {...at}
                largeAndFeatured={idx === 0 || idx === 1}
              />
            )
          })}
        </div>
      </section>
    )
  }
}

export default SupportedAdSection
