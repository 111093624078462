import React from 'react'

import * as styles from './FeatureCard.module.scss'

class FeatureCard extends React.Component {
  render() {
    return <div className={styles.feature_card}>
      <div className={styles.title}>{this.props.title}</div>
      <div className={styles.description}>{this.props.description}</div>
      <img src={this.props.illustration} alt="illustration" />
    </div>
  }
}

export default FeatureCard
