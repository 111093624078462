// extracted by mini-css-extract-plugin
export var marketer_section = "MarketerSection-module--marketer_section--Jf0RP";
export var animation_container = "MarketerSection-module--animation_container--MTbAq";
export var subtitle = "MarketerSection-module--subtitle--ZsNN0";
export var title = "MarketerSection-module--title--f9Nfv";
export var mobile_left_button = "MarketerSection-module--mobile_left_button--LrK20";
export var mobile_right_button = "MarketerSection-module--mobile_right_button--DnC0X";
export var selector = "MarketerSection-module--selector--KF-6U";
export var options_container = "MarketerSection-module--options_container--2H1gy";
export var option = "MarketerSection-module--option--j8LHq";
export var label = "MarketerSection-module--label--3+ZX8";
export var active = "MarketerSection-module--active--iRfwR";
export var track = "MarketerSection-module--track--tJf9R";
export var slider = "MarketerSection-module--slider--A5rF7";