import cx from "classnames";
import React from "react";
import Chevron from "../../images/chevron.svg";
import Link from "../Link";
import * as styles from "./TextButton.module.scss";

// TODO:: hover chevron colour changes

class TextButton extends React.Component {
  render() {
    const className = cx(styles.text_button, this.props.className);
    return (
      <div className={className}>
        <Link href={this.props.href} onClick={this.props.onClick}>
          {this.props.text}
        </Link>
        <Chevron />
      </div>
    );
  }
}

export default TextButton;
