import cx from "classnames";
import React from "react";
import * as styles from "./FeaturedSupportedAd.module.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const FeaturedSupportedAd = (props) => {
  const {
    title,
    description,
    illustration,
    newAdType,
    managedService,
    largeAndFeatured
  } = props;

  const {t} = useTranslation();
  
  return (
    <div
      className={
        largeAndFeatured
          ? cx(styles.supportedDisplay, styles.largeAndFeatured)
          : styles.supportedDisplay
      }
    >
      <img
        className={styles.displayImage}
        src={illustration?.file?.url}
        alt={title}
      />
      <span className={styles.displayTitle}>{title}</span>
      {(newAdType || managedService) && (
        <span className={styles.badges}>
          {newAdType && <span className={styles.badge}>{t("New Ad Type")}</span>}
          {managedService && (
            <span className={cx(styles.badge, styles.purple)}>
              {t("Managed Service")}
            </span>
          )}
        </span>
      )}
      <span className={styles.displayDescription}>
        {description.description}
      </span>
    </div>
  );
}

export default FeaturedSupportedAd;
