import cx from "classnames";
import Img from "gatsby-image";
import * as _ from "lodash";
import React from "react";
import * as styles from "./BrandBar.module.scss";

class BrandBar extends React.Component {
  render() {
    return (
      <section className={styles.brand_bar}>
        <div className={cx(styles.brand_bar_container, "container")}>
          {_.map(this.props.customers, (customer, idx) => {
            return (
              <Img
                key={idx}
                alt={customer.customerName}
                className={styles.brand_icon}
                fluid={customer.logo.fluid}
              />
            );
          })}
        </div>
      </section>
    );
  }
}

export default BrandBar;
